<template>
  <div>
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
  </div>
</template>

<script>
  export default {
    name: 'MembersList',
    data() {
      let _this = this
      return {
        opt: {
          title: '会员列表',
          search: [{
              key: "memberName",
              label: "会员名称",
              maxlength: 24,
            },
            {
              key: "mobile",
              label: "会员手机号",
              rules: [{
                validator: _this.validMemberPhone
              }]
            },
            {
              key: "cardNum",
              label: "会员卡号",
            },
          ],
          columns: [
            // {
            //   label: "会员编号",
            //   key: "cardCode"
            // },
            {
              label: "会员卡号",
              key: "cardNum"
            },
            {
              label: "会员名称",
              key: "memberName"
            },
            {
              label: "性别",
              key: "sexStr"
            },
          
            {
              label: "出生日期",
              key: "birthday"
            },
            {
              label: "手机号码",
              key: "mobile"
            },
            {
              label:"会员余额",
              key:"amountStr"
            },
            {
              label: "注册会员时间",
              key: "createdTime"
            },
            {
              label: "操作",
              key: "action",
              type: "action-but",
              opt: {
                list: [{
                  label: "详情",
                  on(row) {
                    _this.onDetailClick(row)
                  }
                }]
              }
            },
          ]
        }
      }
    },

    methods: {
      onGet(opt) {
        let searchForm = opt.searchForm
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
        }
        if (searchForm.memberName) {
          dto.memberName = searchForm.memberName
        }
        if (searchForm.mobile) {
          dto.mobile = searchForm.mobile
        }
        if (searchForm.cardNum) {
          dto.cardNum = searchForm.cardNum
        }
        this.post('mall-service/consumer-amount/page/query', dto, {
          isUseResponse: true,
        }).then(res => {     
          let obj = {
          data: [],
          total: 0,
        }
          obj.data = res.data.data.list;
          obj.total = res.data.data.total;
          if (obj.data) {
            obj.data.forEach(item => {
              item.sexStr = item.sex ? ['男', '女'][Number(item.sex) - 1] : '--';
              item.memberName = item.memberName ? item.memberName : '--';
              item.birthday = item.birthday ? item.birthday : '--';
              item.mobile = item.mobile ? item.mobile : '--';
              item.cardNum = item.cardNum ? item.cardNum : '--';
              item.amountStr = item.amount ? '￥' + Number(item.amount).toFixed(2) : '￥0.00';
            })
          }
          opt.cb(obj)
        })
      },
      validMemberPhone(rule, value, callback) {
        if (value == "" || value == undefined) {
          callback();
        } else {
          let reg = /^1[3-9](\d){9}$/;
          if (!reg.test(value)) {
            callback(new Error("手机号码格式不对"));
          } else {
            callback();
          }
        }
      },

      onDetailClick(item) {
        this.$router.push({
          path: '/main/membersManagement/detail',
          query: {
            userId: item.userId,
            id: item.id,
          }
        })
        this.cache.set('memberDetailTabIndex',0)
      },

    }
  }
</script>

<style>

</style>